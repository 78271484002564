require('core-js');require('regenerator-runtime/runtime');// Foundation
import $ from 'jquery';
Array.prototype.flat;
window.jQuery = $;
window.$ = $;
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
require('../../../wdh-foundation/dgs-utils/dgs-utils.js');

// Feature
require('../../corporate-template/js/feature/cookies.js');
require('../../corporate-template/js/feature/intro-banner.js');
require('./feature/resource-center');
require('./feature/ifu-download-center.js');
require('./feature/wdh-image-spot');

// Project
require('../../corporate-template/js/project/main.js');
require('@demant/wdh-gdpr/lib/default.implementation.js');
require('./project/header.js');