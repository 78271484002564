(function ($) {
  var $mobileNavInner = $('.component.navigation .component-content .mobile-nav .mobile-nav-inner');
  var $mobileNav = $('.component.navigation .component-content .mobile-nav');
  function setMenuClass() {
    if (window.innerWidth < 768) {
      $('.component.navigation .component-content').addClass("burger-menu");
    } else {
      $('.component.navigation .component-content').removeClass("burger-menu");
    }
  }
  setMenuClass();
  var $burgerMenu = $('.component.navigation .component-content.burger-menu');
  function setActive() {
    if (window.innerWidth < 768) {
      $mobileNav.on('click', function () {
        $burgerMenu.toggleClass("menu-active");
        $mobileNavInner.toggleClass("menu-active");
        $mobileNav.toggleClass("menu-active");
        $('.grid-12').toggleClass("menu-active");
        $('body').toggleClass("menu-active");
      });
    }
  }
  setActive();
  $(window).resize(function () {
    setMenuClass();
    setActive();
  });
})(jQuery);