import PropTypes from 'prop-types';
import ContentType from '../model/content-type';
import Item from '../model/item';
export default {
  settings: PropTypes.shape({
    errorMessage: PropTypes.string,
    dataEndpoint: PropTypes.string,
    maxListCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    baseEndpoint: PropTypes.string
  }),
  dictionary: PropTypes.shape({
    filterby: PropTypes.string,
    viewall: PropTypes.string,
    showresults: PropTypes.string,
    contenttypes: PropTypes.string,
    viewallitems: PropTypes.string,
    back: PropTypes.string,
    download: PropTypes.string,
    file: PropTypes.string,
    pdf: PropTypes.string,
    img: PropTypes.string,
    video: PropTypes.string,
    embed: PropTypes.string,
    loadmore: PropTypes.string,
    IFUDownloadCenter: PropTypes.string,
    clearfilters: PropTypes.string
  }),
  contentTypes: PropTypes.arrayOf(PropTypes.instanceOf(ContentType)),
  item: PropTypes.instanceOf(Item)
};